<template>
	<li class="partner__item" v-if="data">
		<h1 class="partner__title">{{ data.title }}</h1>
		<p class="partner__text editor" v-html="data.text"></p>
	</li>
</template>

<script>
export default {
	name: 'PartnerCollapseItem',

	props: {
		data: {
			type: Object,
		},
	},
}
</script>
